<template>
  <div class="details-empty-state__wrapper">
    <div class="details-empty-state__img-wrapper">
      <img
        class="details-empty-state__img"
        src="@/assets/images/rule-validation/export_icon.svg"
        alt="empty-state-img"
      />
    </div>
    <h1 class="details-empty-state__header">
      on demand export
    </h1>
    <p class="details-empty-state__paragraph">
      These are files exported from Windchill along that day. Select one to check
      either BOM or test case and unusable choices validations.
    </p>
  </div>
</template>

<script>
  export default {
    name: 'DetailsEmptyState'
  }
</script>

<style lang="scss">

  .details-empty-state {
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__img-wrapper {
      width: 200px;
      height: 200px;
      margin: 32px;
    }

    &__paragraph {
      max-width: 640px;
      text-align: center;
      font-weight: 400;
      line-height: 27px;
      font-size: 18px;
      color: $mi-anthracite-800;
    }

    &__header {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      color: $mi-red-500;
    }
  }
</style>
