<template>
  <mi-refresh-btn
    class="on-demand-exports__refresh-btn"
    @clicked-refresh-btn="handleClick"
  ></mi-refresh-btn>
  <div class="on-demand-exports__divider"></div>
  <mi-btn
    class="on-demand-exports__filter-btn on-demand-exports__creator-filter-btn"
    :class="activeFilters.has(userExportFilters.CREATOR.key) ? 'on-demand-exports__filter-btn--selected' : ''"
    flat
    @click="handleFilter(userExportFilters.CREATOR.value, user.username)"
  >
    My exports
  </mi-btn>
  <div class="on-demand-exports__divider"></div>
  <mi-btn
    class="on-demand-exports__filter-btn on-demand-exports__bom-filter-btn"
    :class="activeFilters.has(userExportFilters.BOM.key) ? 'on-demand-exports__filter-btn--selected' : ''"
    flat
    @click="handleFilter(userExportFilters.BOM.value, true)"
  >
    BOM
  </mi-btn>
  <mi-btn
    class="on-demand-exports__filter-btn on-demand-exports__basic-filter-btn"
    :class="activeFilters.has(userExportFilters.BASIC.key) ? 'on-demand-exports__filter-btn--selected' : ''"
    flat
    @click="handleFilter(userExportFilters.BASIC.value, false)"
  >
    Basic
  </mi-btn>
</template>

<script>

  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { USER_EXPORT_FILTER, USER_EXPORT_FILTERS } from '@/constants'
  import {
    HANDLE_INITIAL_STATE_SELECTED_EXPORT, SET_FILTER_USER_EXPORTS,
    SET_FILTERED_USER_EXPORTS
  } from '@/store/modules/rule-validation/mutationTypes'
  import { mapMutations, mapState } from 'vuex'

  export default {
    name: 'FiltersBar',
    components: { MiBtn },
    emits: ['clicked-refresh-btn'],
    data: () => ({
      userExportFilters: USER_EXPORT_FILTERS,
      activeFilters: new Map(),
      filters: []
    }),
    computed: {
      ...mapState(['user'])
    },
    methods: {
      // eslint-disable-next-line max-len,vue/max-len
      ...mapMutations('rule-validation', {
        SET_FILTERED_USER_EXPORTS,
        HANDLE_INITIAL_STATE_SELECTED_EXPORT,
        SET_FILTER_USER_EXPORTS
      }),

      handleClick() {
        this.$emit('clicked-refresh-btn', true)
      },
      updateActiveFilter(type, val) {
        let filterType
        if (type === USER_EXPORT_FILTERS.CREATOR.key) {
          filterType = USER_EXPORT_FILTERS.CREATOR.key
        }
        else {
          filterType = val ? USER_EXPORT_FILTERS.BOM.key : USER_EXPORT_FILTERS.BASIC.key
        }

        const hasFilter = this.activeFilters.has(filterType)

        if (hasFilter) {
          this.activeFilters.delete(filterType)
        }
        else {
          if (filterType === USER_EXPORT_FILTERS.BOM.key || filterType === USER_EXPORT_FILTERS.BASIC.key) {
            this.activeFilters.delete(USER_EXPORT_FILTERS.BOM.key)
            this.activeFilters.delete(USER_EXPORT_FILTERS.BASIC.key)
          }

          this.activeFilters.set(filterType)
        }

        return filterType
      },
      handleFilter(type, val) {
        const newFilter = this.updateActiveFilter(type, val)

        if (newFilter) this.setFilters(type, val)

        this.SET_FILTERED_USER_EXPORTS()
        this.HANDLE_INITIAL_STATE_SELECTED_EXPORT()
      },
      setFilters(type, val) {
        const filterOptions = {
          [USER_EXPORT_FILTER.USER.key]: { ...USER_EXPORT_FILTER.USER, value: val.toString() },
          [USER_EXPORT_FILTER.TYPE.key]: { ...USER_EXPORT_FILTER.TYPE, value: val.toString() }
        }
        this.SET_FILTER_USER_EXPORTS(filterOptions[type])
      }
    }
  }
</script>

<style lang="scss">
  .on-demand-exports {
    &__refresh-btn {
      width: 48px;
      height: 48px;
      padding-right: 1rem;
    }

    &__divider {
      border: 1px solid $mi-anthracite-50;
      height: 27px;
    }

    &__filter-btn {
      margin: 0 16px;

      &--selected {
        background-color: $mi-anthracite-800;
        /* stylelint-disable declaration-no-important */
        color: #ffffff !important;
      }
    }

    &__basic-filter-btn {
      margin-left: 0;
    }
  }
</style>
