<template>
  <template v-if="activeValidationType === validationOptions.BOM.label">
    <mi-btn
      color="accent"
      icon="plus"
      icon-size="14px"
      @click="handleNewBomValidation"
    >
      New Validation
    </mi-btn>
  </template>

  <template v-else>
    <mi-btn-dropdown
      color="accent"
      :menu-offset="[0, 4]"
      class="q-ml-sm"
      label="New Validation"
      auto-close
    >
      <mi-list>
        <!-- Menu options -->
        <mi-list-item
          v-for="({ title, key, exportFn }, type) in NEW_VALIDATIONS_OPTIONS"
          :key="type"
          clickable
          @click="exportFn(key)"
        >
          <mi-list-item-section>
            <mi-list-item-label> {{ title }} </mi-list-item-label>
          </mi-list-item-section>
        </mi-list-item>
      </mi-list>
    </mi-btn-dropdown>
  </template>
</template>

<script>
  import { VALIDATIONS_OPTIONS } from '@/constants'

  export default {
    name: 'NewValidationDialogBtn',
    props: {
      activeValidationType: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['open:new-validation-dialog'],
    data() {
      return {
        validationOptions: VALIDATIONS_OPTIONS,
        validationType: '',
        NEW_VALIDATIONS_OPTIONS: {
          TEST_CASES: {
            title: 'Test case',
            key: VALIDATIONS_OPTIONS.TEST_CASES.label,
            exportFn: this.handleNewBasicValidation
          },
          UNUSABLE_CHOICES: {
            title: 'Unusable choice',
            key: VALIDATIONS_OPTIONS.UNUSABLE_CHOICES.label,
            exportFn: this.handleNewBasicValidation
          }
        },
        eventBody: {
          validationType: '',
          isNewValidationDialogShown: false
        }
      }
    },
    methods: {
      handleNewBomValidation() {
        this.eventBody.isNewValidationDialogShown = true
        this.eventBody.validationType = this.validationOptions.BOM.label
        this.dispatchEvent()
      },
      handleNewBasicValidation(type) {
        this.eventBody.isNewValidationDialogShown = true
        this.eventBody.validationType = type
        this.dispatchEvent()
      },
      dispatchEvent() {
        this.$emit('open:new-validation-dialog', this.eventBody)
      }
    }
  }
</script>
