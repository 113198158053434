<template>
  <div
    class="validations-list"
    data-testid="validations-list"
  >
    <div data-testid="validations-list__wrapper">
      <div
        v-if="validations"
        class="validations-list__wrapper--content"
      >
        <mi-table
          ref="miTable"
          :columns="columns"
          :rows="validations"
          class="validations-table full-height"
          column-sort-order="da"
          loading-label="Loading validations. Please wait ..."
          no-data-label="No validations found"
          row-key="id"
          virtual-scroll-sticky-size-start="40"
          hide-pagination
          sticky-header
          striped
          virtual-scroll
          wrap-cells
          @row-click="onRowClick"
        >
          <template #body-cell-actions="{ value, row }">
            <mi-td class="validations-table__status-cell">
              <div class="flex items-center">
                <!-- Delete validation button -->
                <mi-btn v-if="renderAction(value?.title)" color="transparent" @click.stop>
                  <q-icon class="text-dark" size="small">
                    <img
                      src="@/assets/images/dots_vertical.svg"
                      alt="Delete Validation"
                    />
                  </q-icon>
                  <mi-menu>
                    <q-list class="validations-list__wrapper--content__actions-list">
                      <q-item v-show="isUnusableChoice(row)" v-close-popup clickable @click="onExportClick(row)">
                        <q-item-section>
                          <q-item-label>Export validation</q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item v-show="!isUnusableChoice(row)" v-close-popup clickable @click="onDeleteClick(row.id)">
                        <q-item-section>
                          <q-item-label>Delete</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </mi-menu>
                </mi-btn>
                <!-- Navigate to validation results button -->
                <mi-btn
                  v-if="renderAction(value?.title) && !isUnusableChoice(row)"
                  color="transparent"
                >
                  <q-icon class="text-dark" size="small">
                    <img
                      src="@/assets/images/redirect_arrow.svg"
                      alt="Navigate to Validation Results"
                    />
                  </q-icon>
                </mi-btn>
              </div>
            </mi-td>
          </template>
          <template #body-cell-status="{ value }">
            <mi-td class="bom-validations-table__status-cell">
              <div class="flex">
                <mi-badge class="text-uppercase q-mr-xs" :color="value?.color">
                  {{ value?.title }}
                </mi-badge>
              </div>
            </mi-td>
          </template>
        </mi-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { validationColumns } from '@/components/rule-validation/utils/columns/validationColumns'
  import { isValidationAvailable } from
    '@/components/rule-validation/utils/check-validation-status/checkValidationStatus'
  import { exportInvalidChoicesExcel } from '@/api'
  import { exportFile } from 'quasar'
  import { VALIDATIONS_OPTIONS } from '@/constants'

  export default {
    name: 'ValidationsList',
    props: {
      validations: {
        type: Object,
        required: true
      },
      validationType: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['redirect-to-validation-page', 'on-delete-validation'],
    data: () => ({
      columns: []
    }),
    created() {
      this.columns = validationColumns(this.validationType)
    },
    methods: {
      isUnusableChoice(row) {
        return row?.basicValidationType === VALIDATIONS_OPTIONS.UNUSABLE_CHOICES.key
      },
      onRowClick(_evt, row) {
        if (!this.isUnusableChoice(row)) this.$emit('redirect-to-validation-page', row)
      },
      renderAction(status) {
        return status ? isValidationAvailable(status.replace(' ', '_').toUpperCase()) : ''
      },
      onDeleteClick(validationId) {
        this.$emit('on-delete-validation', validationId)
      },
      async onExportClick(row) {
        if (!row?.id) return
        const excelFile = await exportInvalidChoicesExcel(row.id)
        const filename = `Not_usable_choices_${ row.name }_${ row.businessName }_${ row.validationDate }.xlsx`

        exportFile(filename, new Blob([excelFile]))
      }
    }
  }
</script>

<style lang="scss">
  .validations-list {
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2.7%;

      &--content {
        width: 100%;

        .items-center {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
        }

        &__actions-list {
          width: 160px;
        }
      }
    }
  }
</style>
