<template>
  <div class="browse-portfolio-list-wrapper">
    <div class="browse-portfolio-list">
      <div
        :class="headerColor
          ? `browse-portfolio-list__header--${ items.size ? headerColor.toLowerCase() : 'grey' }`
          : ''"
        class="browse-portfolio-list__header flex q-virtual-scroll--skip items-center"
      >
        <img
          v-if="isCategoriesList"
          class="browse-portfolio-img"
          src="@/assets/images/browse-portfolio/categories.svg"
          alt="categories"
        />
        <img
          v-else
          class="browse-portfolio-img"
          src="@/assets/images/browse-portfolio/options.svg"
          alt="categories"
        />
        <label class="browse-portfolio-label">{{ list.label }}</label>
        <label class="browse-portfolio-num">{{ items.size }}</label>
      </div>
      <!-- Scroll area -->
      <mi-virtual-scroll
        v-if="items.size"
        :items="items.results"
        virtual-scroll-item-size="5"
        virtual-scroll-slice-size="5"
        separator
        class="full-height"
      >
        <template v-if="isChoiceList || isComponentVariants" #default="{ item }">
          <browse-portfolio-list-item-group
            :item="item"
            :color="headerColor.toLowerCase()"
            @selected:item="handleSelectedItem"
          >
          </browse-portfolio-list-item-group>
        </template>
        <template v-else #default="{ item }">
          <browse-portfolio-list-item
            :item="item"
            :color="headerColor.toLowerCase()"
            :type="list.key"
            @selected:item="handleSelectedItem"
          >
          </browse-portfolio-list-item>
        </template>
      </mi-virtual-scroll>
      <div v-show="!items.size" class="browse-portfolio-list__unselected-content">
        <div class="browse-portfolio-list__unselected-content--body">
          <img
            class="browse-portfolio-list__unselected-content--body--img"
            src="@/assets/images/browse-portfolio/unselected.svg"
            alt="categories"
          />
          <label class="browse-portfolio-list__unselected-content--body--text">{{ list.text || '' }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { ELEMENT_COLORS } from '@/constants'
  import {
    BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN,
    BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW
  } from '@/constants/browsePortfolio'
  import BrowsePortfolioListItem from '@/components/browse-portfolio/BrowsePortfolioListItem.vue'
  import { createNamespacedHelpers } from 'vuex'
  import BrowsePortfolioListItemGroup from '@/components/browse-portfolio/BrowsePortfolioListItemGroup.vue'

  const { mapActions, mapGetters } = createNamespacedHelpers('browse-portfolio')

  export default {
    name: 'BrowsePortfolioList',
    components: {
      BrowsePortfolioListItemGroup,
      BrowsePortfolioListItem
    },
    props: {
      headerColor: {
        type: String,
        required: false,
        default: '',
        validator: value => Object.values(ELEMENT_COLORS).includes(value) || !value
      },
      list: {
        type: Object,
        required: true
      },
      items: {
        type: Object,
        required: true
      },
      pmEncodedBusinessName: {
        type: String,
        required: false,
        default: ''
      }
    },
    data: () => ({
      isCategoriesList: false,
      isOptionsList: false,
      isChoiceList: false,
      isHighGroups: false,
      isComponents: false,
      isComponentVariants: false
    }),
    created() {
      this.isCategoriesList = this.list.key === BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.CATEGORIES.key
      this.isOptionsList = this.list.key === BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.OPTIONS.key
      this.isChoiceList = this.list.key === BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.CHOICES.key
      this.isHighGroups = this.list.key === BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.HIGH_GROUPS.key
      this.isComponents = this.list.key === BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.COMPONENTS.key
      this.isComponentVariants = this.list.key === BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.COMPONENT_VARIANTS.key
    },
    methods: {
      ...mapActions([
        'fetchCategories',
        'fetchOptions',
        'fetchChoices',
        'fetchComponents',
        'fetchComponentVariants'
      ]),
      ...mapGetters(['getOptions', 'getChoices']),

      handleSelectedItem(itemData) {
        itemData.pmEncodedBusinessName = this.pmEncodedBusinessName
        const yellow = ['Categories', 'Option(s)', 'Choice(s)']
        const green = ['High Groups', 'Components', 'Components Variants']

        if (this.isCategoriesList && yellow.includes(this.list.label)) {
          this.fetchOptions(itemData)
        }
        else if (this.isOptionsList && yellow.includes(this.list.label)) {
          this.fetchChoices(itemData)
        }

        if (this.isHighGroups && green.includes(this.list.label)) {
          this.fetchComponents(itemData)
        }
        else if (this.isComponents) {
          this.fetchComponentVariants(itemData)
        }
      }
    }
  }
</script>

<style lang="scss">
  $header-height: 56px;
  $table-border-width: 1px;

  .browse-portfolio-list-wrapper {
    width: 100%;
    height: 100%;
    font-family: $mi-typography-font-family;

    .q-virtual-scroll__content {
      height: 70vh;
      overflow: auto;
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: $mi-silver-100;
    }

    ::-webkit-scrollbar-track {
      background: #ffffff;
    }
  }

  .browse-portfolio-list {
    position: fixed;
    margin-right: 24px;
    border: $table-border-width solid $mi-silver-50;
    width: 25%;

    &__scroll-area {
      height: calc(100% - #{$header-height});
    }

    &__header {
      height: $header-height;
      padding: .75rem 10px;
      background-color: $mi-silver-50;
      grid-template-columns: 3vw 18vw 1vw;
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: start;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      &--yellow {
        background-color: $mi-yellow-400;
      }

      &--green {
        background-color: $mi-green-500;
      }

      &--grey {
        background-color: $mi-silver-100;
      }

      .browse-portfolio-label {
        width: 80%;
      }

      .browse-portfolio-img {
        margin-left: 11px;
        margin-right: 13px;
      }
    }

    &__unselected-content {
      height: 390px;

      &--body {
        width: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 60px auto auto;

        &--img {
          margin-bottom: 67px;
        }

        &--text {
          font-family: $mi-typography-font-family-condensed;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
</style>
