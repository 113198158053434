<template>
  <div class="browse-portfolio-search-wrapper">
    <template v-if="shouldRender">
      <div class="browse-portfolio-search__header">
        <div class="browse-portfolio-search__header--tabs">
          <mi-tabs
            v-model="activeWorld"
          >
            <mi-tab
              v-for="(settingValue) in existingWorlds"
              :key="settingValue.key"
              :name="settingValue.key"
              :label="settingValue.label"
              @click="RESET_WORLD"
            ></mi-tab>
          </mi-tabs>
        </div>
        <div class="browse-portfolio-search__header--btn">
          <mi-btn
            color="accent"
            disable
          >
            Send to search
          </mi-btn>
        </div>
      </div>
      <div class="browse-portfolio-search__content">
        <div class="browse-portfolio-search__content--list">
          <mi-tab-panels v-model="activeWorld">
            <mi-tab-panel :name="activeWorld" class="q-px-none">
              <template v-if="activeWorld === existingWorlds.YELLOW.key">
                <browse-portfolio-lists :active-world="activeWorld"></browse-portfolio-lists>
              </template>
              <template v-if="activeWorld === existingWorlds.GREEN.key">
                <browse-portfolio-lists :active-world="activeWorld"></browse-portfolio-lists>
              </template>
            </mi-tab-panel>
          </mi-tab-panels>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="browse-portfolio-search__not-available">
        <p>The Browse Portfolio feature is not available for the selected Product Model.</p>
      </div>
    </template>
  </div>
</template>

<script>
  import { BROWSE_PORTFOLIO_WORLDS } from '@/constants/browsePortfolio'
  import BrowsePortfolioLists from '@/components/browse-portfolio/BrowsePortfolioLists.vue'
  import { RESET_WORLD } from '@/store/modules/browse-portfolio/mutationTypes'
  import { createNamespacedHelpers } from 'vuex'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    BROWSE_PORTFOLIO_PAGE_OPENED,
    BROWSE_PORTFOLIO_YELLOW_WORLD_ACCESS,
    BROWSE_PORTFOLIO_GREEN_WORLD_ACCESS
  } from '@/utils/analytics/constants'

  const { mapMutations } = createNamespacedHelpers('browse-portfolio')

  export default {
    name: 'BrowsePortfolioSearch',
    components: {
      BrowsePortfolioLists
    },
    data: () => ({
      existingWorlds: BROWSE_PORTFOLIO_WORLDS,
      activeWorld: BROWSE_PORTFOLIO_WORLDS.YELLOW.key
    }),
    watch: {
      activeWorld() {
        if (this.activeWorld === this.existingWorlds.YELLOW.key) {
          recordAnalytics(BROWSE_PORTFOLIO_YELLOW_WORLD_ACCESS)
        }
        else {
          recordAnalytics(BROWSE_PORTFOLIO_GREEN_WORLD_ACCESS)
        }
      }
    },
    created() {
      recordAnalytics(BROWSE_PORTFOLIO_PAGE_OPENED)
      this.shouldRender()
    },
    methods: {
      ...mapMutations({ RESET_WORLD }),

      shouldRender() {
        const productModel = this.$q.localStorage.getItem('productModel')
        return productModel?.encodedBusinessName !== null
      }
    }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-search-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .browse-portfolio-search {
    &__header {
      width: 79%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: $mi-typography-font-family-condensed;

      &--tabs {
        .q-tab {
          padding: 0 1rem;
        }
      }
    }

    &__content {
      padding-top: 24px;
    }

    &__not-available {
      height: 15vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: $mi-typography-font-family-condensed;
      font-size: 1.25em;
    }
  }
</style>
