<template>
  <mi-dialog
    key="on-demand-new-validation-dialog"
    :model-value="modelValue"
    class="on-demand-new-validation-dialog"
    no-backdrop-dismiss
    custom-design-dialog
    @update:model-value="emitUpdateModelValue"
  >
    <new-validation-dialog-header
      :new-validation-type="validationType"
    >
    </new-validation-dialog-header>
    <div class="on-demand-new-validation-dialog__container">
      <new-validation-dialog-body v-if="isBomValidation">
        <new-bom-validation-form
          @update:model-value="emitUpdateModelValue"
        >
        </new-bom-validation-form>
      </new-validation-dialog-body>
      <new-validation-dialog-body v-else-if="isTestCaseValidation">
        <new-test-case-validation-form
          @update:model-value="emitUpdateModelValue"
        >
        </new-test-case-validation-form>
      </new-validation-dialog-body>
      <new-validation-dialog-body v-else>
        <new-unusable-choice-validation-form
          @update:model-value="emitUpdateModelValue"
        >
        </new-unusable-choice-validation-form>
      </new-validation-dialog-body>
    </div>
  </mi-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import NewValidationDialogHeader from
    '@/components/rule-validation/on-demand-exports/new-validation-dialog/NewValidationDialogHeader.vue'
  import NewValidationDialogBody
    from '@/components/rule-validation/on-demand-exports/new-validation-dialog/NewValidationDialogBody.vue'
  import NewBomValidationForm from
    '@/components/rule-validation/on-demand-exports/form/NewBomValidationForm.vue'
  import NewTestCaseValidationForm from
    '@/components/rule-validation/on-demand-exports/form/NewTestCaseValidationForm.vue'
  import NewUnusableChoiceValidationForm
    from '@/components/rule-validation/on-demand-exports/form/NewUnusableChoiceValidationForm.vue'
  import { VALIDATIONS_OPTIONS } from '@/constants'

  const { mapState } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'NewValidationDialog',
    components: {
      NewUnusableChoiceValidationForm,
      NewTestCaseValidationForm,
      NewBomValidationForm,
      NewValidationDialogBody,
      NewValidationDialogHeader
    },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      validationType: {
        type: String,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      isBomValidation: false,
      isTestCaseValidation: false
    }),
    computed: {
      ...mapState(['selectedUserExportType', 'userExports'])
    },
    beforeUpdate() {
      this.isBomValidation = this.validationType === VALIDATIONS_OPTIONS.BOM.label
      this.isTestCaseValidation = this.validationType === VALIDATIONS_OPTIONS.TEST_CASES.label
    },
    methods: {
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .on-demand-new-validation-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 80vw;
      height: fit-content;
      min-height: 400px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      height: calc(100% - 111px);
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .on-demand-new-validation-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
        height: 545px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .on-demand-new-validation-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
        height: 585px;
      }
    }
  }
</style>
