<template>
  <div class="browse-portfolio-list-item-wrapper">
    <div class="browse-portfolio-list-item">
      <mi-list-item
        :id="item.id"
        ref="list"
        :key="item.id"
        class="items-center mi-list-item"
        clickable
        @click="handleClick(item, type, color)"
      >
        <search-results-item-title :persistent="true" :code-id="item.id2" :name="item.name">
        </search-results-item-title>
        <mi-icon class="q-ml-sm" name="caret-right" size="8px"></mi-icon>
      </mi-list-item>
    </div>
  </div>
</template>

<script>
  import SearchResultsItemTitle from '@/components/search/search-results/SearchResultsItemTitle.vue'
  import {
    BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN,
    BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW
  } from '@/constants/browsePortfolio'

  export default {
    name: 'BrowsePortfolioListItem',
    components: { SearchResultsItemTitle },
    props: {
      item: {
        type: Array,
        required: true
      },
      color: {
        type: String,
        required: false,
        default: 'yellow'
      },
      type: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['selected:item'],
    data: () => ({
      isActive: false,
      className: '',
      classQuery: ''
    }),
    created() {
      this.className = `browse-portfolio-list-item-selected-${ this.color.toLowerCase() }`
      this.classQuery = `.${ this.className }`
    },
    methods: {
      handleClick(item, type) {
        this.setSelected(type)

        this.$emit('selected:item', {
          id: item.id,
          isActive: this.isActive
        })
      },
      setSelected(type) {
        if (type === BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW.CATEGORIES.key) this.cleanSelected()
        if (type === BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN.HIGH_GROUPS.key) this.cleanSelected()

        this.isActive = !this.isActive

        const elClassList = this.$refs.list.$el.classList
        this.isActive ? elClassList.add(this.className) : elClassList.remove(this.className)
      },
      cleanSelected() {
        document.querySelectorAll(this.classQuery).forEach(el => {
          el.classList.remove(this.className)
        })
        this.isActive = (this.$refs.list.$el.classList.contains(this.className))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-list-item {
    border: 1px solid #e6e6e6;
  }

  .browse-portfolio-list-item-selected-yellow {
    background-color: lighten($mi-yellow-400, 35%);
  }

  .browse-portfolio-list-item-selected-green {
    background-color: lighten($mi-green-200, 9%);
  }
</style>
