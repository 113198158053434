<template>
  <mi-list-item-section class="search-results-item-title ellipsis-2-lines">
    <template v-if="persistent">
      <span> {{ codeId }} </span>
      <span v-if="codeId"> - </span>
      <span> {{ name }} </span>
    </template>

    <template v-else>
      <span v-show="resultsFilterSearch.showCodeId"> {{ codeId }} </span>
      <span v-show="resultsFilterSearch.showCodeId && resultsFilterSearch.showName"> - </span>
      <span v-show="resultsFilterSearch.showName"> {{ name }} </span>
    </template>
  </mi-list-item-section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const { mapState } = createNamespacedHelpers('search')

  export default {
    name: 'SearchResultsItemTitle',
    props: {
      codeId: {
        type: String,
        required: false,
        default: ''
      },
      name: {
        type: String,
        required: false,
        default: ''
      },
      persistent: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapState(['resultsFilterSearch'])
    }
  }
</script>

<style lang="scss" scoped>
  .search-results-item-title.mi-list-item-section {
    word-break: break-word;
  }
</style>
