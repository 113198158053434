<template>
  <div class="on-demand-exports__items">
    <mi-list
      v-for="(userExport, index) in getList()"
      :key="index"
      class="item"
    >
      <mi-list-item
        class="q-px-none"
        clickable
        :class="{ index: isSelected }"
      >
        <mi-list-item-section>
          <exports-list-item
            :user-export="userExport"
            :is-in-progress="isInProgress"
          ></exports-list-item>
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>
  </div>
</template>

<script>
  import ExportsListItem from '@/components/rule-validation/on-demand-exports/list/ExportsListItem.vue'
  import { NUM_SKELETON } from '@/constants'

  export default {
    name: 'ExportsListItems',
    components: { ExportsListItem },
    props: {
      userExports: {
        type: Array,
        required: true
      },
      isInProgress: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        isSelected: true
      }
    },
    methods: {
      getList() {
        return this.isInProgress ? NUM_SKELETON.LIST_ITEMS : this.userExports
      }
    }
  }
</script>

<style>
  .selected {
    background-color: #f8f8f8;
  }
</style>
