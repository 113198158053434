<template>
  <div class="on-demand-exports full-height">
    <div class="on-demand-exports__filter-wrapper">
      <filters-bar
        @clicked-refresh-btn="refreshUserExports"
      ></filters-bar>
    </div>
    <div class="on-demand-exports__wrapper column full-height">
      <div class="on-demand-exports__list">
        <exports-list
          :user-exports="userExports"
        ></exports-list>
      </div>
      <div class="on-demand-exports__details">
        <export-details></export-details>
      </div>
    </div>
  </div>
</template>

<script>
  import { ON_DEMAND_EXPORTS_VALIDATIONS_PAGE } from '@/utils/analytics/constants'
  import ExportsList from '@/components/rule-validation/on-demand-exports/list/ExportsList.vue'
  import ExportDetails from '@/components/rule-validation/on-demand-exports/details/ExportDetails.vue'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { createNamespacedHelpers } from 'vuex'
  import FiltersBar from '@/components/rule-validation/on-demand-exports/filters/FiltersBar.vue'

  const {
    mapActions,
    mapState,
    mapMutations
  } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'OnDemandExports',
    components: { FiltersBar, ExportsList, ExportDetails },
    computed: {
      ...mapState(['userExports'])
    },
    async created() {
      await this.getUserExports()
    },
    beforeMount() {
      recordAnalytics(ON_DEMAND_EXPORTS_VALIDATIONS_PAGE)
    },
    methods: {
      ...mapMutations(['HANDLE_SELECTED_EXPORT']),
      ...mapActions(['fetchUserExports', 'clearFilterType']),

      async getUserExports() {
        this.clearFilterType()
        await this.fetchUserExports()
      },
      async refreshUserExports() {
        const { selectedUserExport } = this.userExports
        this.HANDLE_SELECTED_EXPORT({})
        await this.getUserExports()
        if (this.userExports?.results.some(userExport => userExport.id === selectedUserExport.id)) {
          this.HANDLE_SELECTED_EXPORT(selectedUserExport)
        }
      }
    }
  }
</script>

<style lang="scss">
  .on-demand-exports {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__wrapper {
      display: flex;
      flex-direction: row;
    }

    &__list {
      padding: 1.5rem;
      width: 30%;
      max-height: 90%;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    &__details {
      width: 70%;
      overflow: hidden;
    }

    &__filter-wrapper {
      height: 3.75rem;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: .5rem 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .24);
      position: relative;
    }
  }
</style>
