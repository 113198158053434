<template>
  <div
    v-if="!hasSelectedUserExport"
    data-testid="export-details-empty-content"
    name="empty-details"
    class="on-demand-exports-details__content on-demand-exports-details__content--empty"
  >
    <details-empty-state></details-empty-state>
  </div>
  <div
    v-else
    class="on-demand-exports-details__content"
    data-testid="export-details-content"
  >
    <div class="on-demand-exports-details__content--filled">
      <div class="on-demand-exports__details-header">
        <!-- details of selected export -->
        <slot name="details" class="details__header">
          <details-header
            :selected-export="userExports.selectedUserExport"
          ></details-header>
        </slot>
      </div>
      <div class="validations-list__wrapper">
        <div class="validations-list__header">
          <div class="flex justify-start">
            <mi-tabs
              v-show="activeValidationType === validationOptions.BOM.label"
              v-model="activeValidationType"
            >
              <mi-tab
                v-for="(settingValue) in validationSettings"
                :key="settingValue.key"
                :name="settingValue.key"
                :label="settingValue.label"
              ></mi-tab>
            </mi-tabs>
          </div>

          <div class="flex justify-end flex-end">
            <new-validation-dialog-btn
              :active-validation-type="activeValidationType"
              @open:new-validation-dialog="handleNewValidation"
            ></new-validation-dialog-btn>

            <new-validation-dialog
              v-model="isNewValidationDialogShown"
              :validation-type="newValidationType"
            >
            </new-validation-dialog>
          </div>
        </div>

        <div class="validations-list__content">
          <mi-tab-panels v-model="activeValidationType">
            <mi-tab-panel :name="activeValidationType" class="q-px-none">
              <validations-empty-state
                v-if="!hasValidations"
                ref="validationsEmptyState"
                :type="validationOptions[activeValidationType]?.label"
                @click="handleNewValidationViaEmptyState"
              ></validations-empty-state>
              <validations-list
                v-else
                v-show="shouldShow"
                :validations="validations.results"
                :validation-type="validationOptions[activeValidationType]?.label"
                @redirect-to-validation-page="redirectToValidationPage"
                @on-delete-validation="onDeleteValidationActionClicked"
              ></validations-list>
            </mi-tab-panel>
          </mi-tab-panels>
          <!-- Delete validation dialog -->
          <validation-delete-dialog
            v-if="isDeleteValidationDialogShown"
            v-model="isDeleteValidationDialogShown"
            :delete-fn="deleteValidation"
            :validation-id="validationIdToBeDeleted"
            :is-delete-from-on-demand-exports="true"
            @delete:validation="updateValidationsList"
          ></validation-delete-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import DetailsEmptyState from '@/components/rule-validation/on-demand-exports/details/DetailsEmptyState.vue'
  import NewValidationDialog from
    '@/components/rule-validation/on-demand-exports/new-validation-dialog/NewValidationDialog.vue'
  import ValidationsEmptyState from '@/components/rule-validation/on-demand-exports/details/ValidationsEmptyState.vue'
  import ValidationsList from '@/components/rule-validation/on-demand-exports/details/ValidationsList.vue'
  import { VALIDATIONS_EMPTY_STATE_OPTIONS, VALIDATIONS_OPTIONS } from '@/constants/ruleValidations'
  import { ON_DEMAND_BOM_VALIDATIONS_ROUTE, TEST_CASE_VALIDATION_RESULTS_ROUTE } from '@/router/routeNames'
  import DetailsHeader from '@/components/rule-validation/on-demand-exports/details/DetailsHeader.vue'
  import ValidationDeleteDialog from '@/components/validations/ValidationDeleteDialog.vue'
  import { isValidationAvailable } from
    '@/components/rule-validation/utils/check-validation-status/checkValidationStatus'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { ON_DEMAND_EXPORTS_OPENED_VAL_ONE } from '@/utils/analytics/constants'
  import { deleteBOMValidation, deleteTestCaseValidation } from '@/api'
  import NewValidationDialogBtn
    from '@/components/rule-validation/on-demand-exports/new-validation-dialog/NewValidationDialogBtn.vue'

  const { mapGetters, mapActions, mapState } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'ExportDetails',
    components: {
      NewValidationDialogBtn,
      DetailsEmptyState,
      ValidationsEmptyState,
      NewValidationDialog,
      ValidationsList,
      DetailsHeader,
      ValidationDeleteDialog
    },
    data: () => ({
      validationSettings: '',
      validationOptions: VALIDATIONS_OPTIONS,
      activeValidationType: '',
      isNewValidationDialogShown: false,
      isDeleteValidationDialogShown: false,
      validationIdToBeDeleted: '',
      newValidationType: '',
      deleteBOMValidation,
      shouldShow: false
    }),
    computed: {
      ...mapGetters([
        'getUserExportType',
        'hasSelectedUserExport',
        'hasValidations'
      ]),
      ...mapState([
        'validations',
        'userExports'
      ]),
      deleteValidation() {
        const { selectedUserExport: { onDemandExportMetadata } } = this.userExports
        return onDemandExportMetadata.containsBOM ? deleteBOMValidation : deleteTestCaseValidation
      }
    },
    watch: {
      'userExports.selectedUserExport': async function (value) {
        if (!Object.keys(value).length) return
        this.shouldShow = false
        await this.getValidations(value)
      },
      'userExports.selectedUserExportType': async function (value) {
        this.setValidationType(value)
      }
    },
    created() {
      const { selectedUserExportType: exportType } = this.userExports
      if (exportType) {
        const value = this.validationOptions[exportType]?.label
        this.setValidationType(value)
        this.updateValidationsList()
      }
    },
    methods: {
      ...mapActions(['fetchValidations', 'fetchTestCasesValidations']),

      handleNewValidation(data) {
        this.newValidationType = data.validationType ?? this.validationOptions[this.activeValidationType]?.label
        this.isNewValidationDialogShown = data.isNewValidationDialogShown ?? true
      },
      handleNewValidationViaEmptyState() {
        const data = {
          validationType: this.validationOptions[this.activeValidationType]?.label,
          isNewValidationDialogShown: true
        }

        if (data.validationType === this.validationOptions.BOM.label) this.handleNewValidation(data)
      },
      redirectToValidationPage(validation) {
        if (!isValidationAvailable(validation.status)) return

        if (validation.id && validation.testCaseId) {
          const route = this.$router.resolve({
            name: TEST_CASE_VALIDATION_RESULTS_ROUTE.name,
            params: { testCaseId: validation.testCaseId, testCaseValidationId: validation.id }
          })
          recordAnalytics(ON_DEMAND_EXPORTS_OPENED_VAL_ONE)
          if (route?.href) window.open(route.href, '_blank')
          return
        }

        if (validation.id && this.userExports.selectedUserExport.id) {
          const route = this.$router.resolve({
            name: ON_DEMAND_BOM_VALIDATIONS_ROUTE.name,
            params: { id: validation.id, userExportID: this.userExports.selectedUserExport.id }
          })
          recordAnalytics(ON_DEMAND_EXPORTS_OPENED_VAL_ONE)
          if (route?.href) window.open(route.href, '_blank')
        }
      },
      async updateValidationsList() {
        await this.getValidations(this.userExports.selectedUserExport)
      },
      onDeleteValidationActionClicked(validationId) {
        this.validationIdToBeDeleted = validationId
        this.isDeleteValidationDialogShown = true
      },
      async getValidations(value) {
        this.shouldShow = value.onDemandExportMetadata.containsBOM
          ? await this.fetchValidations()
          : await this.fetchTestCasesValidations()
      },
      setValidationType(value) {
        this.activeValidationType = value
        this.validationSettings = VALIDATIONS_EMPTY_STATE_OPTIONS[value]
      }
    }
  }
</script>

<style lang="scss" scoped>
  button {
    max-height: 32px;
  }

  .flex-end {
    align-content: flex-end;
  }

  .on-demand-exports-details {
    &__content {
      height: 100%;
      border-left: 2px solid $mi-anthracite-50;

      &--empty {
        background-color: #eaedf34d;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--filled {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding: 1.5rem 2rem;
      }
    }
  }

  .validations-list {
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__header {
      display: grid;
      grid-template-columns: 4fr 1fr;
      margin-bottom: .75rem;
      width: 100%;
      font-family: $mi-typography-font-family-condensed;
    }

    &__content {
      width: 100%;
    }
  }

  .on-demand-exports {
    &__details-header {
      width: 100%;
      margin-bottom: 2%;
    }
  }
</style>
