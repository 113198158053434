import { addPrefixToComplexPlanningPeriod } from '@/utils/planningPeriod'
import formatDate from '@/utils/formatDate'
import { RESULT_STATUS } from '@/constants'

const BASIC_VALIDATION_TYPE = {
  TEST_CASE: 'Test case',
  UNUSABLE_CHOICES: 'Unusable choice'
}

export function basicColumns() {
  return [
    {
      name: 'businessName',
      label: 'Type',
      align: 'left',
      field: ({ basicValidationType }) => BASIC_VALIDATION_TYPE[basicValidationType],
      sortable: true,
      style: 'width: 40%'
    },
    {
      name: 'planningPeriodRange',
      align: 'left',
      label: 'Planning Period',
      field: ({ planningPeriodRange: { from, to } = {} } = {}) => ` ${ addPrefixToComplexPlanningPeriod(from, to) }`,
      sortable: true,
      style: 'width: 25%'
    },
    {
      name: 'user',
      label: 'Owner',
      align: 'left',
      field: ({ user } = {}) => `${ user ?? '-' }`,
      sortable: true,
      style: 'width: 10%'
    },
    {
      name: 'validationDate',
      label: 'Date Created',
      align: 'left',
      sortable: true,
      field: ({ validationDate } = {}) => `${ validationDate ? formatDate(validationDate) : '' }`,
      style: 'width: 15%'
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      field: ({ status } = {}) => RESULT_STATUS[status],
      sortable: true
    },
    {
      name: 'actions',
      label: '',
      align: 'left',
      field: ({ status } = {}) => RESULT_STATUS[status],
      style: 'width: 10%'
    }
  ]
}
