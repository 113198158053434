<template>
  <mi-form class="new-bom-validation-form" @submit="handleSubmit">
    <div class="row new-bom-validation-form__inputs">
      <div class="col-4">
        <mi-text-field
          v-model="validationForm.planningPeriod"
          :rules="[customPlanningPeriodValidationRules]"
          :class="isPlanningPeriodEmpty && 'new-bom-validation-form__inputs-mask'"
          label="Planning period"
          mask="###### - ######"
          fill-mask="_"
          outlined
          prefix="PP"
        >
        </mi-text-field>
      </div>
    </div>
    <div class="new-bom-validation-form__actions">
      <mi-btn flat @click="closeDialog"> Cancel </mi-btn>
      <mi-btn type="submit" :loading="isCreateValidationLoading">
        <span> Create validation </span>
      </mi-btn>
    </div>
  </mi-form>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { required } from '@/utils/validators'
  import { MIN_LENGTH_ERROR } from '@/utils/validators/errorMessages'
  import { removePlanningPeriodMask } from '@/utils/planningPeriod'
  import { createOnDemandExportsUnusableChoiceValidation } from '@/api'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    ON_DEMAND_EXPORTS_UNUSABLE_CHOICE_VALIDATION_CREATED
  } from '@/utils/analytics/constants'

  const { mapGetters, mapActions } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'NewUnusableChoiceValidationForm',
    props: {
      validationType: {
        type: Object,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      isCreateValidationLoading: false,
      validationForm: {
        planningPeriod: ''
      }
    }),
    computed: {
      ...mapGetters([
        'getSelectedUserExport'
      ]),

      isPlanningPeriodEmpty() {
        const hasNumber = /\d/
        return !hasNumber.test(this.validationForm.planningPeriod)
      }
    },
    async created() {
      await this.getTestCasesFiles()
    },
    methods: {
      ...mapActions([
        'fetchTestCasesValidations',
        'getTestCasesFiles'
      ]),
      customPlanningPeriodValidationRules() {
        const PLANNING_PERIOD_LENGTH = 12
        if (this.isPlanningPeriodEmpty) return required()
        if (!this.isPlanningPeriodEmpty && this.validationForm.planningPeriod.includes('_')) {
          return MIN_LENGTH_ERROR.replace('{length}', PLANNING_PERIOD_LENGTH)
        }
        return true
      },
      closeDialog() {
        this.$emit('update:model-value')
      },
      async handleSubmit() {
        this.isCreateValidationLoading = true
        try {
          const { initialPP, finalPP } = removePlanningPeriodMask(this.validationForm.planningPeriod)

          const result = await createOnDemandExportsUnusableChoiceValidation({
            userExportID: this.getSelectedUserExport.id,
            initialPP,
            finalPP
          })

          if (result) {
            notify({
              title: 'COMPLETED',
              content: 'Unusable Choice validation was successfully created.',
              type: 'dark',
              progress: false
            })
            this.closeDialog()
          }

          this.fetchTestCasesValidations()
          recordAnalytics(ON_DEMAND_EXPORTS_UNUSABLE_CHOICE_VALIDATION_CREATED)
        }
        catch (err) {
          throw new Error('An error occurred trying to submit a new Unusable Choice validation', err)
        }
        finally {
          this.isCreateValidationLoading = false
        }
      }
    }
  }
</script>

<style lang="scss">
  .new-bom-validation-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &__inputs {
      padding: 36px 40px;

      &-mask {
        .q-field__prefix,
        input {
          font-size: .875rem;
          color: $mi-anthracite-400;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      width: 100%;
      gap: 16px;
      height: 84px;
      padding: 24px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, .24);
    }
  }

  .q-notification.mi-notification--dark_no_icon {
    max-width: 924px;
    width: 50%;
    margin-bottom: 22.5%;
  }

  .new-bom-validation-form__inputs .mi-field.q-field .q-field__control:before {
    border: #738497 2px solid;
  }
</style>
