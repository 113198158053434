import { addPrefixToComplexPlanningPeriod } from '@/utils/planningPeriod'
import formatDate from '@/utils/formatDate'
import { RESULT_STATUS } from '@/constants'

export function bomValidationColumns() {
  return [
    {
      name: 'componentId',
      label: 'Component ID',
      align: 'left',
      field: 'componentId',
      classes: 'text-uppercase',
      sortable: true,
      style: 'width: 40%'
    },
    {
      name: 'planningPeriodRange',
      align: 'left',
      label: 'Planning Period',
      field: ({ planningPeriodRange: { from, to } = {} } = {}) => ` ${ addPrefixToComplexPlanningPeriod(from, to) }`,
      sortable: true,
      style: 'width: 25%'
    },
    {
      name: 'user',
      label: 'Owner',
      align: 'left',
      field: 'user',
      sortable: true,
      style: 'width: 10%'
    },
    {
      name: 'validationDate',
      label: 'Date Created',
      align: 'left',
      sortable: true,
      field: ({ startDate } = {}) => `${ startDate ? formatDate(startDate) : '' }`,
      style: 'width: 15%'
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      field: ({ status } = {}) => RESULT_STATUS[status],
      sortable: true
    },
    {
      name: 'actions',
      label: '',
      align: 'left',
      field: ({ status } = {}) => RESULT_STATUS[status],
      style: 'width: 10%'
    }
  ]
}
