<template>
  <div v-if="categoriesItems && activeWorld ==='YELLOW'" class="browse-portfolio-lists-wrapper">
    <template v-for="(list) in listsYellow" :key="list.key">
      <browse-portfolio-list
        :header-color="activeWorld"
        :list="list"
        :items="getItemsYellow(list.key)"
        :pm-encoded-business-name="pmEncodedBusinessName"
      ></browse-portfolio-list>
    </template>
  </div>
  <div v-if="categoriesItems && activeWorld ==='GREEN'" class="browse-portfolio-lists-wrapper">
    <template v-for="(list) in listsGreen" :key="list.key">
      <browse-portfolio-list
        :header-color="activeWorld"
        :list="list"
        :items="getItemsGreen(list.key)"
        :pm-encoded-business-name="pmEncodedBusinessName"
      ></browse-portfolio-list>
    </template>
  </div>
</template>

<script>

  import BrowsePortfolioList from '@/components/browse-portfolio/BrowsePortfolioList.vue'
  import {
    BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN,
    BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW
  } from '@/constants/browsePortfolio'
  import { createNamespacedHelpers } from 'vuex'

  const { mapActions, mapState } = createNamespacedHelpers('browse-portfolio')

  export default {
    name: 'BrowsePortfolioLists',
    components: { BrowsePortfolioList },
    props: {
      activeWorld: {
        type: String,
        required: false,
        default: ''
      }
    },
    data: () => ({
      listsGreen: BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN,
      listsYellow: BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW,
      pmEncodedBusinessName: ''
    }),
    computed: {
      ...mapState([
        'categoriesItems',
        'optionsItems',
        'choicesItems',
        'highGroups',
        'components',
        'componentVariants'
      ])
    },
    created() {
      const { encodedBusinessName } = this.getEncodedBusinessName()
      this.pmEncodedBusinessName = encodedBusinessName
    },
    async beforeMount() {
      await this.getCategories()
      await this.getHighGroups()
    },
    methods: {
      ...mapActions(['fetchCategories', 'fetchHighGroups']),

      async getCategories() {
        await this.fetchCategories(this.pmEncodedBusinessName)
      },
      async getHighGroups() {
        await this.fetchHighGroups(this.pmEncodedBusinessName)
      },
      getEncodedBusinessName() {
        return this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL) ?? ''
      },
      getItemsYellow(key) {
        if (key === this.listsYellow.CATEGORIES.key) return this.categoriesItems
        if (key === this.listsYellow.OPTIONS.key) return this.optionsItems
        if (key === this.listsYellow.CHOICES.key) return this.choicesItems
        return []
      },
      getItemsGreen(key) {
        if (key === this.listsGreen.HIGH_GROUPS.key) return this.highGroups
        if (key === this.listsGreen.COMPONENTS.key) return this.components
        if (key === this.listsGreen.COMPONENT_VARIANTS.key) return this.componentVariants
        return []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-lists-wrapper {
    width: 80%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
</style>
