<template>
  <div class="exports__header-list">
    <h5 class="q-mt-none">
      Windchill exports
    </h5>
    <!-- Number of user exports -->
    <slot name="exports-number">
      <div class="files-found text-overline text-weight-bold q-mb-md">
        {{ userExportsSize }} files found
      </div>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'ExportsListHeader',
    props: {
      userExportsSize: {
        type: Number,
        require: true,
        default: 0
      }
    }
  }
</script>

<style lang="scss">
  .exports {
    &__header-list {
      h5 {
        margin-bottom: .5rem;
      }

      .files-found {
        color: $mi-silver-700;
      }
    }
  }
</style>
