<template>
  <div class="new-validation-dialog-body">
    <div class="new-validation-dialog-body__content">
      <mi-icon
        name="file-download"
        class="new-validation-dialog-body__content-icon"
      >
      </mi-icon>
      <div class="new-validation-dialog-body__content-info">
        <span class="new-validation-dialog-body__content-info__title">
          File exported from Windchill
        </span>
        <span class="new-validation-dialog-body__content-info__subtitle">
          {{ getSelectedUserExport.onDemandExportMetadata?.exportName || 'Name from exported file' }}
        </span>
      </div>
    </div>
    <mi-separator class="new-validation-dialog-body__separator"></mi-separator>
    <slot></slot>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const { mapGetters } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'NewValidationDialogBody',
    computed: {
      ...mapGetters([
        'getSelectedUserExport'
      ])
    }
  }
</script>

<style lang="scss">
  .new-validation-dialog-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .q-separator {
      background-color: $mi-anthracite-50;
    }

    &__separator {
      margin: 0 40px;
    }

    &__content {
      display: flex;
      align-items: center;
      padding: 28px 40px;

      &-info {
        display: flex;
        flex-direction: column;
        padding-top: 12px;

        &__title {
          font-weight: 700;
          color: $mi-anthracite-800;
        }

        &__subtitle {
          font-size: 1.125rem;
          font-weight: 400;
          color: $mi-anthracite-800;
          display: block;
          padding: 6px 0;
        }
      }

      &-icon {
        font-size: 1.75rem;
        color: $mi-blue-500;
        padding-right: 1.125rem;
      }
    }
  }
</style>
