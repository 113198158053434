<template>
  <div class="on-demand-exports__header-list">
    <exports-list-header
      :user-exports-size="userExports.size"
    ></exports-list-header>
  </div>

  <div class="on-demand-exports__content-list">
    <slot v-if="isUnavailableUserExports" name="empty-list">
      <list-empty-state></list-empty-state>
    </slot>
    <slot v-else name="list">
      <exports-list-items
        :user-exports="userExports.filteredResults"
        :is-in-progress="isGettingUserExports"
      ></exports-list-items>
    </slot>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import ExportsListHeader from '@/components/rule-validation/on-demand-exports/list/ExportsListHeader.vue'
  import ListEmptyState from '@/components/rule-validation/on-demand-exports/list/ListEmptyState.vue'
  import ExportsListItems from '@/components/rule-validation/on-demand-exports/list/ExportsListItems.vue'

  const { mapGetters } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'ExportsList',
    components: { ListEmptyState, ExportsListHeader, ExportsListItems },
    props: {
      userExports: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'hasUserExports',
        'isGettingUserExports',
        'isUnavailableUserExports'
      ])
    }
  }
</script>
