<template>
  <div class="details__tab">
    <div class="details__wrapper">
      <h1 class="details__header">
        {{ selectedExport.onDemandExportMetadata?.exportName }}
      </h1>
      <div>
      </div>
    </div>
    <sub-details
      :details="selectedExport.onDemandExportMetadata || {}"
    ></sub-details>
  </div>
</template>

<script>
  import SubDetails from '@/components/rule-validation/on-demand-exports/details/SubDetails.vue'

  export default {
    name: 'DetailsHeader',
    components: { SubDetails },
    props: {
      selectedExport: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss">
  .details {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__tab {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    &__header {
      line-height: 42px;
      font-size: 28px;
      font-weight: 700;
      color: $mi-anthracite-800;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 25px;
      height: 80px;
      margin-bottom: 30px;
    }

    &__header:before {
      content: "";
      display: block;
      width: 4px;
      height: 88px;
      background: $mi-red-500;
      left: 0;
    }
  }
</style>
