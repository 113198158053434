<template>
  <div class="on-demand-exports__item-row">
    <div class="on-demand-exports__item-col">
      <template v-if="isInProgress">
        <mi-skeleton width="40px" height="40px"></mi-skeleton>
      </template>
      <template v-else-if="isBom">
        <img
          class="icon_export__img"
          src="@/assets/images/rule-validation/icon_export.svg"
          alt="empty-state-img"
        />
      </template>
      <template v-else>
        <img
          class="icon_export_outline__img"
          src="@/assets/images/rule-validation/icon_export_outline.svg"
          alt="empty-state-img"
        />
      </template>
    </div>
    <div
      class="on-demand-exports__item-col"
      @click="HANDLE_SELECTED_EXPORT(userExport)"
    >
      <p
        class="q-mb-sm"
        :class="isInProgress ? 'info-primary-skeleton' : 'info-primary'"
      >
        <template v-if="isInProgress">
          <mi-skeleton width="50px" height="10px"></mi-skeleton>
        </template>
        <template v-else>
          {{ getUserExportInfo(userExport, 'exportName', true) }}
        </template>
      </p>
      <p class="q-mb-sm info-secondary text-uppercase text-weight-bold text-family-condensed">
        <template v-if="isInProgress">
          <mi-skeleton width="150px" height="10px"></mi-skeleton>
        </template>
        <template v-else>
          {{ isBom ? getUserExportInfo(userExport, 'businessName') : '' }}
        </template>
      </p>
      <p class="q-mb-sm info-secondary">
        <template v-if="isInProgress">
          <mi-skeleton width="100px" height="10px"></mi-skeleton>
        </template>
        <template v-else>
          {{ getUserExportInfo(userExport, 'createdAt', true) }}
        </template>
      </p>
    </div>
    <div class="on-demand-exports__item-col">
      <p class="q-mb-sm info-secondary">
        <template v-if="isInProgress">
          <mi-skeleton width="30px" height="10px"></mi-skeleton>
        </template>
        <template v-else>
          {{ getUserExportInfo(userExport, 'creator', true) }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import MiSkeleton from '@/packages/@mi-library/MiSkeleton/MiSkeleton.vue'

  const {
    mapMutations,
    mapState
  } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'ExportsListItem',
    components: { MiSkeleton },
    props: {
      userExport: {
        type: Object,
        required: true
      },
      isInProgress: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      isBom: false
    }),
    computed: {
      ...mapState(['userExports'])
    },
    created() {
      this.isBomUserExport(this.userExport)
    },
    beforeUpdate() {
      this.isBomUserExport(this.userExport)
    },
    methods: {
      ...mapMutations([
        'HANDLE_SELECTED_EXPORT',
        'SET_VALIDATIONS_EXPORTS'
      ]),
      getFromMetadata(userExport, type) {
        return (userExport.onDemandExportMetadata?.[type])
          ? userExport.onDemandExportMetadata[type] : ''
      },
      getUserExportInfo(userExport, type, onDemandExportMetadata = false) {
        if (!userExport) return ''
        return (onDemandExportMetadata) ? this.getFromMetadata(userExport, type) : userExport[type]
      },
      isBomUserExport(userExport) {
        this.isBom = userExport?.onDemandExportMetadata?.containsBOM
      }
    }
  }
</script>

<style lang="scss" scoped>

  .on-demand-exports {
    &__item-row {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: flex-start;
      height: 70px;
    }

    &__item-col {
      padding: 2%;
      width: 25%;
      height: 100%;
      display: flex;

      .info-primary {
        color: $mi-anthracite-800;
        margin-bottom: 0;
        word-wrap: break-word;
      }

      .info-primary-skeleton {
        color: $mi-anthracite-800;
      }

      .info-secondary {
        color: $mi-anthracite-500;
      }
    }

    &__item-col:nth-child(1) {
      justify-content: center;
      width: 15%;
    }

    &__item-col:nth-child(2) {
      width: 100%;
      max-width: 70%;
      justify-content: center;
      flex-direction: column;
    }

    &__item-col:nth-child(3) {
      justify-content: flex-end;
      align-items: center;
    }
  }

</style>
