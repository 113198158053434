<template>
  <div class="list-empty-state">
    <span class="list-empty-state__message">
      No export files found
    </span>
    <div class="list-empty-state__box">
      <div class="list-empty-state__box-title">
        <span class="mi-icon-help-circle-outline"></span>
        <span class="list-empty-state__message">
          Not finding a file?
        </span>
      </div>
      <span class="list-empty-state__box-body">
        Files will be displayed only after being completely exported from Windchill and up for a week.
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ListEmptyState'
  }
</script>

<style lang="scss">
  .list-empty-state {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10rem;
    margin-top: 10rem;

    &__message {
      font-weight: 700;
      line-height: 1.125rem;
      font-size: .75rem;
      color: $mi-anthracite-800;
    }

    &__box {
      background-color: $mi-silver-50;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8.48889px 12.7333px;
      margin: 1.25rem;

      &-title {
        display: flex;
        align-items: center;
        gap: 9px;
        padding-top: .5rem;
      }

      &-body {
        color: $mi-anthracite-800;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        padding: .5rem;
        margin: 0 1rem;
      }
    }
  }

</style>
