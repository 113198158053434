<template>
  <div class="validations-empty-state" :class="isBomValidation ? 'validations-empty-state-bom' : ''">
    <div class="validations-empty-state__wrapper">
      <div class="validations-empty-state__wrapper--content">
        <div class="validations-empty-state__img-wrapper">
          <img
            v-if="isBomValidation"
            class="validations-empty-state__img"
            src="@/assets/images/rule-validation/add.svg"
            alt="empty-state-add-img"
          />
          <img
            v-else
            class="validations-empty-state__img"
            src="@/assets/images/rule-validation/folder_multi.svg"
            alt="empty-state-add-img"
          />
        </div>
        <p class="validations-empty-state__paragraph">
          {{ getEmptyStateText() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import {
    VALIDATIONS_EMPTY_STATE_TEXT_BOM,
    VALIDATIONS_EMPTY_STATE_TEXT_GENERAL,
    VALIDATIONS_OPTIONS
  } from '@/constants/ruleValidations'

  const {
    mapState
  } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'ValidationsEmptyList',
    props: {
      type: {
        type: String,
        required: true,
        default: ''
      }
    },
    data() {
      return {
        isBomValidation: false
      }
    },
    computed: {
      ...mapState(['selectedUserExportType'])
    },
    beforeMount() {
      this.isBomValidation = this.isBomValidations(this.type)
    },
    methods: {
      isBomValidations(type) {
        return VALIDATIONS_OPTIONS.BOM.label === type
      },
      getEmptyStateText() {
        return this.isBomValidation ? VALIDATIONS_EMPTY_STATE_TEXT_BOM : VALIDATIONS_EMPTY_STATE_TEXT_GENERAL
      }
    }
  }
</script>

<style lang="scss">
  .validations-empty-state {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper {
      width: 100%;
      border-radius: .5rem;
      border: dashed .1rem $mi-anthracite-200;

      &--content {
        padding: 1.5rem;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }

    &__img {
      color: $mi-anthracite-200;
      margin-bottom: 1em;
    }

    &__paragraph {
      font-weight: 700;
      line-height: 1.125rem;
      font-size: .75rem;
      color: $mi-anthracite-800;
    }
  }

  .validations-empty-state-bom:hover {
    cursor: pointer;
  }
</style>
