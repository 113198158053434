<template>
  <!-- FIRST ROW -->
  <div class="export__subdetails row">
    <!-- EXPORT TYPE -->
    <div class="export__subdetails-wrapper col">
      <div class="export-subdetails row">
        <div class="export-subdetails__icon-wrapper col">
          <img
            class="export-subdetails__icon"
            src="@/assets/images/rule-validation/header-icons/export-type.svg"
            alt="export icon"
          />
        </div>
        <div class="export-subdetails__details-wrapper col">
          <h6 class="export-subdetails__title">export type</h6>
          <p class="export-subdetails__text">{{ details?.containsBOM ? 'BOM' : 'Basic' }}</p>
        </div>
      </div>
    </div>
    <!-- CREATOR -->
    <div class="export__subdetails-wrapper col">
      <div class="export-subdetails row">
        <div class="export-subdetails__icon-wrapper col">
          <img
            class="export-subdetails__icon"
            src="@/assets/images/rule-validation/header-icons/user-icon.svg"
            alt="export icon"
          />
        </div>
        <div class="export-subdetails__details-wrapper col">
          <h6 class="export-subdetails__title">user</h6>
          <p class="export-subdetails__text">{{ details.creator ? details.creator : '-' }}</p>
        </div>
      </div>
    </div>
    <!-- CREATED AT -->
    <div class="export__subdetails-wrapper col">
      <div class="export-subdetails row">
        <div class="export-subdetails__icon-wrapper col">
          <img
            class="export-subdetails__icon"
            src="@/assets/images/rule-validation/header-icons/download.svg"
            alt="export icon"
          />
        </div>
        <div class="export-subdetails__details-wrapper col">
          <h6 class="export-subdetails__title">date imported</h6>
          <p class="export-subdetails__text">{{ details.createdAt ? details.createdAt : '-' }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- SECOND ROW -->
  <div v-if="isBomDetails(details)" class="export__subdetails export__subdetails-bom row">
    <!-- TAR -->
    <div class="export__subdetails-wrapper col">
      <div class="export-subdetails row">
        <div class="export-subdetails__icon-wrapper col">
          <img
            class="export-subdetails__icon"
            src="@/assets/images/rule-validation/header-icons/TAR.svg"
            alt="export icon"
          />
        </div>
        <div class="export-subdetails__details-wrapper col">
          <h6 class="export-subdetails__title">tar</h6>
          <p class="export-subdetails__text">
            {{ details.tarStateConfig ? details.tarStateConfig : '-' }}
          </p>
        </div>
      </div>
    </div>
    <!-- CONDITIONAL RULES CONFIG -->
    <div class="export__subdetails-wrapper col">
      <div class="export-subdetails row">
        <div class="export-subdetails__icon-wrapper col">
          <img
            class="export-subdetails__icon"
            src="@/assets/images/rule-validation/header-icons/TAR.svg"
            alt="export icon"
          />
        </div>
        <div class="export-subdetails__details-wrapper col">
          <h6 class="export-subdetails__title">status</h6>
          <p class="export-subdetails__text">
            {{ details.conditionalRulesConfig ? details.conditionalRulesConfig : '-' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SubDetails',
    props: {
      details: {
        type: Object,
        required: true
      }
    },
    methods: {
      isBomDetails(details) {
        return details?.containsBOM ?? false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .export {
    &__subdetails {
      margin-left: 1%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }

    &__subdetails-wrapper {
      max-width: 30%;
      width: 50%;
    }
  }

  .export-subdetails {
    display: flex;
    height: 50%;
    align-items: center;

    &__icon-wrapper {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 10%;
      width: 15%;
      height: 100%;
    }

    &__icon {
      width: 40%;
      height: 40%;
    }

    &__title {
      text-transform: uppercase;
      margin: 0;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    }

    &__text {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    &__details-wrapper {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      padding-left: 2%;
    }
  }

  .export__subdetails-bom {
    margin-top: 2%;
  }
</style>
